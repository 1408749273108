import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import {
    bookCamperAction,
    BookCamperActionType,
    setClearBookFormAction,
    checkAvailabilityAction,
    setUnavailableDatesAction,
    // saveFormDetailsAction,
    // paymentAction,
} from '~actions/book'
import { startLoading, stopLoading } from '~actions/loading'
import {
    FETCH_UNAVAILABLE_DATES_LOADER,
    FETCH_UNAVAILABLE_DATES_SERVICE,
    SUBMIT_BOOKING_FORM_LOADER,
    SUBMIT_BOOKING_FORM_SERVICE,
} from '~constants/api'
import { services } from '~utils/services'
import { handleSagaError } from '~saga/utils'
import { createNotification } from '~utils/utils'
import { NOTIFICATION_TYPE_SUCCESS } from '~constants/notification'
import { i18nUtil } from '~utils/i18n'
import { selectedLanguageSelector, translatedTextSelector } from '~reducers/navigation'
// import { onEasyPayPayment } from '~saga/easyPay'

function* onBookCamper({ payload }: BookCamperActionType) {
    const {
        caravanId,
        occupancy,
        boutiqueOption,
        destination,
        fullName,
        email,
        phoneNumber,
        phoneCode,
        additionalRequirements,
        selectedDate,
        // paid,
        // paymentProvider,
    } = payload
    try {
        yield put(startLoading({ loader: SUBMIT_BOOKING_FORM_LOADER }))
        const language = (yield select(selectedLanguageSelector)).value
        yield call(services[SUBMIT_BOOKING_FORM_SERVICE], {
            body: {
                caravanId,
                occupancy,
                boutiqueOption,
                destination,
                fullName,
                email,
                phoneNumber: `${phoneCode}${phoneNumber}`,
                additionalRequirements,
                selectedDate,
                language,
                // paid,
            },
        })

        // const names = fullName.split(' ')
        //
        // if (paid) {
        const translationText = yield select(translatedTextSelector)
        yield call(createNotification, {
            kind: NOTIFICATION_TYPE_SUCCESS,
            message: i18nUtil({ key: 'success-book-message', translationText }),
        })
        yield put(setClearBookFormAction({ shouldClearFormState: true }))
        // } else {
        //     yield put(saveFormDetailsAction(payload))
        //     if (paymentProvider === 'easypay') {
        //         //TODO TESTING remove example customer data
        //         const customer = {
        //             name: `${names[0]} ${names[names.length - 1]}` || 'Customer Example',
        //             email: email || 'customer@example.com',
        //             phone: phoneNumber || '911234567',
        //             phone_indicative: phoneCode || '+351',
        //         }
        //         yield call(onEasyPayPayment, paymentAction({ money: 1, customer }))
        //     } else {
        //         //TODO TESTING remove example customer data
        //         const customer = {
        //             name: `${names[0]} ${names[names.length - 1]}` || 'Customer Example',
        //             email: email || 'customer@example.com',
        //             phone: phoneCode + phoneNumber || '+351911234567',
        //         }
        //         yield call(onRevolutPayment, paymentAction({ money: 1, customer }))
        //     }
        // }
    } catch (error: any) {
        yield call(handleSagaError, { error, title: SUBMIT_BOOKING_FORM_SERVICE })
        yield call(checkAvailabilityAction)
    } finally {
        yield put(stopLoading({ loader: SUBMIT_BOOKING_FORM_LOADER }))
    }
}

function* onCheckAvailability() {
    try {
        yield put(startLoading({ loader: FETCH_UNAVAILABLE_DATES_LOADER }))
        const response = yield call(services[FETCH_UNAVAILABLE_DATES_SERVICE])

        yield put(setUnavailableDatesAction({ listOfDisabledDatesById: response }))
    } catch (error: any) {
        yield call(handleSagaError, { error, title: FETCH_UNAVAILABLE_DATES_SERVICE })
    } finally {
        yield put(stopLoading({ loader: FETCH_UNAVAILABLE_DATES_LOADER }))
    }
}

export function* bookSaga() {
    yield all([takeLatest(bookCamperAction, onBookCamper), takeLatest(checkAvailabilityAction, onCheckAvailability)])
}
