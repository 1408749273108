import React from 'react'
import { Row, Col } from 'reactstrap'
import classNames from 'classnames'
import { i18n } from '~utils/i18n'
import { isPortrait } from '~utils/utils'

export const PrivacyPolicy = () => {
    return (
        <>
            <Row noGutters className={classNames({ 'flex-column': isPortrait() })}>
                <Col className="px-3 mt-3 text-primary" md={6}>
                    <h2 className="header">{i18n({ key: 'privacy-left-header' })}</h2>
                    <p>{i18n({ key: 'privacy-left-numbers-1' })?.map(item => <div>{item}</div>)}</p>
                    <p>
                        {i18n({ key: 'privacy-left-numbers-2' })?.map(item => (
                            <div className={classNames({ 'ps-3': item.startsWith('\u2022') })}>{item}</div>
                        ))}
                    </p>
                    <p>{i18n({ key: 'privacy-left-paragraph-1' })}</p>
                </Col>
                <Col className="px-3 mt-3 text-primary" md={6}>
                    <h2 className="header text-primary">{i18n({ key: 'privacy-right-header' })}</h2>
                    <p>{i18n({ key: 'privacy-right-numbers-1' })?.map(item => <div>{item}</div>)}</p>
                    <p>{i18n({ key: 'privacy-right-numbers-2' })?.map(item => <div>{item}</div>)}</p>
                    <p>{i18n({ key: 'privacy-right-numbers-3' })?.map(item => <div>{item}</div>)}</p>
                    <p>{i18n({ key: 'privacy-right-numbers-4' })?.map(item => <div>{item}</div>)}</p>
                    <p>{i18n({ key: 'privacy-right-numbers-5' })?.map(item => <div>{item}</div>)}</p>
                    <p>{i18n({ key: 'privacy-right-numbers-6' })?.map(item => <div>{item}</div>)}</p>
                    <p>{i18n({ key: 'privacy-right-numbers-7' })?.map(item => <div>{item}</div>)}</p>
                    <p>{i18n({ key: 'privacy-right-numbers-8' })?.map(item => <div>{item}</div>)}</p>
                    <p>
                        {i18n({ key: 'privacy-right-numbers-9' })?.map(item => (
                            <div className={classNames({ 'ps-3': item.startsWith('\u2022') })}>{item}</div>
                        ))}
                    </p>
                </Col>
            </Row>
        </>
    )
}
