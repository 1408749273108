import React from 'react'
import { NOTIFICATION_TYPE_ERROR } from '~constants/notification'
import { createNotification, isTesting } from '~utils/utils'

export interface ServiceError {
    message: string
    code?: string
    traceId?: string
}

export const handleSagaError = ({ error, title }: { error: Error | ServiceError[]; title: string }) => {
    if (!isTesting()) {
        // tslint:disable-next-line:no-console
        console.error(error)
    }

    let errorsArray = Array.isArray(error) ? error : [error]
    // defend agains empty array
    errorsArray = errorsArray.length ? errorsArray : [{ message: 'Service error' }]

    errorsArray.forEach(e => {
        const message = (
            <>
                <div>{`Error - ${title}`}</div>
                {e.message && <p>{e.message}</p>}
                {e.code && <p>Code: {e.code}</p>}
                {e.traceId && <p>Trace Id: {e.traceId}</p>}
            </>
        )

        createNotification({
            message,
            kind: NOTIFICATION_TYPE_ERROR,
            autoHide: false,
        })
    })
}

export const omitUndefinedProps = obj =>
    Object.entries(obj).reduce((acc, [key, value]) => {
        if (obj[key] !== undefined) {
            acc[key] = value
        }

        return acc
    }, {})

export const fileDownload = ({ url }) => {
    const tempLink = document.createElement('a')

    tempLink.href = url
    tempLink.setAttribute('download', 'test')
    tempLink.setAttribute('target', '_blank')
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
}

export const readFileAsText = blob =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()

        // @ts-ignore
        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsText(blob, 'CP1251')
    })
