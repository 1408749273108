// misc
export const CREATED_BY = 'createdBy'
export const UPDATED_BY = 'updatedBy'
export const TIME_STAMP = 'timeStamp'
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm'
export const TIME_STAMP_FORMAT = 'DD/MM/YYYY HH:mm:ss'
export const DATE_FORMAT = 'DD/MM/YYYY'
export const TIME_FORMAT = 'HH:mm'
export const MONTH_FORMAT = 'YYYY-MM'
export const DATA_BY_NAME = 'dataByName'
export const RANGE_DATE_FORMAT = 'YYYY-MM-DD'

export interface OptionListFields {
    label: string
    value: string
    validationFeedbackMessage?: string
}
