import { ActionType, createAction } from 'deox'

export const submitContactFormAction = createAction(
    'SUBMIT_CONTACT_FORM',
    resolve => (payload: { fullName: string; email: string; phoneNumber: string; howCanHelp: string }) =>
        resolve(payload)
)

export const setClearContactFormAction = createAction(
    'SET_CLEAR_CONTACT_FORM',
    resolve => (payload: { shouldClearFormState: boolean }) => resolve(payload)
)

export type SubmitContactFormActionType = ActionType<typeof submitContactFormAction>
