import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import { submitContactFormAction, SubmitContactFormActionType, setClearContactFormAction } from '~actions/contacts'
import { startLoading, stopLoading } from '~actions/loading'
import { SUBMIT_CONTACT_FORM_LOADER, SUBMIT_CONTACT_FORM_SERVICE } from '~constants/api'
import { services } from '~utils/services'
import { handleSagaError } from '~saga/utils'
import { selectedLanguageSelector, translatedTextSelector } from '~reducers/navigation'
import { createNotification } from '~utils/utils'
import { NOTIFICATION_TYPE_SUCCESS } from '~constants/notification'
import { i18nUtil } from '~utils/i18n'

function* onSubmitContactForm({ payload: { fullName, email, phoneNumber, howCanHelp } }: SubmitContactFormActionType) {
    try {
        yield put(startLoading({ loader: SUBMIT_CONTACT_FORM_LOADER }))
        const language = (yield select(selectedLanguageSelector)).value
        yield call(services[SUBMIT_CONTACT_FORM_SERVICE], {
            body: { fullName, email, phoneNumber, howCanHelp, language },
        })

        const translationText = yield select(translatedTextSelector)
        yield call(createNotification, {
            kind: NOTIFICATION_TYPE_SUCCESS,
            message: i18nUtil({ key: 'success-contact-message', translationText }),
        })
        yield put(setClearContactFormAction({ shouldClearFormState: true }))
    } catch (error: any) {
        yield call(handleSagaError, { error, title: SUBMIT_CONTACT_FORM_SERVICE })
    } finally {
        yield put(stopLoading({ loader: SUBMIT_CONTACT_FORM_LOADER }))
    }
}

export function* contactsSaga() {
    yield all([takeLatest(submitContactFormAction, onSubmitContactForm)])
}
