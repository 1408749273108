import { startCheckout } from '@easypaypt/checkout-sdk'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { startLoading, stopLoading } from '~actions/loading'
import { EASYPAY_PAYMENT_LOADER, EASYPAY_PROD_CHECKOUT_SERVICE, EASYPAY_TEST_CHECKOUT_SERVICE } from '~constants/api'
import { isDevelopment } from '~utils/utils'
import { services } from '~utils/services'
import { handleSagaError } from '~saga/utils'
import { bookCamperAction, paymentAction, EasyPayPaymentActionType } from '~actions/book'
import { getStore } from '~components/Root'

const manifesConfig = ({ money, customer }) => ({
    type: ['single'],
    payment: {
        methods: ['cc', 'mb', 'mbw', 'dd', 'vi', 'uf', 'sc'],
        type: 'sale',
        capture: {
            descriptive: 'Caravan rental',
        },
        currency: 'EUR',
        expiration_time: null,
    },
    order: {
        key: 'order-123',
        value: money,
    },
    customer,
})

/**
 * Call the Checkout SDK with manifest and options.
 * In this case, we are using the default id 'easypay-checkout' and passing an 'onClose' handler.
 */
function* initEasypayCheckoutSDK({ manifest, customer, money }) {
    const store = getStore()
    const onCheckoutSuccess = () => {
        const payload = store.getState().book.formDetails
        console.log('payload', payload)
        if (payload) {
            store.dispatch(bookCamperAction({ ...payload, paid: true }))
        }
    }
    const onCheckoutClose = () => {
        checkoutInstance.unmount()
    }
    const onCheckoutError =
        ({ customer, money }) =>
        error => {
            console.log(error)
            checkoutInstance.unmount()
            switch (error.code) {
                case 'checkout-expired':
                    store.dispatch(paymentAction({ customer, money }))
                    break
                case 'already-paid':
                    console.log('Your order was already paid. Thank you.')
                    break
                case 'checkout-canceled':
                    console.log('Your order was canceled.')
                    break
                default:
                    console.log('Unable to process payment, please try again.')
            }
        }
    const config = {
        id: 'easypay-checkout',
        display: 'popup',
        hideDetails: true,
        onClose: onCheckoutClose,
        onSuccess: onCheckoutSuccess,
        onError: onCheckoutError({ customer, money }),
        language: 'en',
        // logoUrl:'https://easypay-cdn-delivery.s3.eu-central-1.amazonaws.com/emails/easypay_logo.svg'
        testing: true, //TODO TESTING remove
    }

    const checkoutInstance = startCheckout(manifest, config)
}

export function* onEasyPayPayment({ payload: { customer, money } }: EasyPayPaymentActionType) {
    const manifest = yield call(onCheckoutManifest, { money, customer })

    yield call(initEasypayCheckoutSDK, { manifest, customer, money })
    const button = document.getElementById('easypay-checkout')
    button?.click()
}

function* onCheckoutManifest({ customer, money }) {
    try {
        yield put(startLoading({ loader: EASYPAY_PAYMENT_LOADER }))
        const name = isDevelopment() ? EASYPAY_TEST_CHECKOUT_SERVICE : EASYPAY_TEST_CHECKOUT_SERVICE
        //TODO TESTING switch
        //const name = isDevelopment() ? EASYPAY_TEST_CHECKOUT_SERVICE : EASYPAY_PROD_CHECKOUT_SERVICE

        return yield call(services[name], { body: manifesConfig({ money, customer }) })
    } catch (error: any) {
        yield call(handleSagaError, { error, title: EASYPAY_PROD_CHECKOUT_SERVICE })
    } finally {
        yield put(stopLoading({ loader: EASYPAY_PAYMENT_LOADER }))
    }
}

export function* easyPaySaga() {
    yield all([takeLatest(paymentAction, onEasyPayPayment)])
}
