import { ActionType, createAction } from 'deox'

export const startLoading = createAction('START_LOADING', resolve => (payload: { loader: string }) => resolve(payload))

export const stopLoading = createAction('STOP_LOADING', resolve => (payload: { loader: string }) => resolve(payload))

type StartLoadingType = ActionType<typeof startLoading>
type StopLoadingType = ActionType<typeof stopLoading>

export type LoadingActionType = StartLoadingType | StopLoadingType
