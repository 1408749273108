import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { DECIMAL_DIGITS_COUNT, NAME } from '~constants/global'
import { servicesDefinitions } from '~utils/servicesDefs'
import { NotificationManager } from '~containers'
import { AlertKind } from '~components/shared'
import { CallBackFunction } from 'react-notification-system'
import { NOTIFICATION_TYPE_INFO } from '~constants/notification'
import { DATE_FORMAT, TIME_STAMP_FORMAT } from '~constants/report'

export const ParseGlobalNetworkError = ({ errorCode, serviceName }) => (
    <span>
        <span>{'System has encountered an error.'}</span>
        <br />
        <span>{`Code: ${errorCode}.`}</span>
        <br />
        <span>{`Error Description: Request ${
            serviceName ? 'for service ' + getRealServiceName(serviceName) : ''
        } failed with status code ${errorCode}`}</span>
    </span>
)

ParseGlobalNetworkError.propTypes = {
    errorCode: PropTypes.any.isRequired,
    serviceName: PropTypes.string.isRequired,
}

const getRealServiceName = serviceName => {
    const service = servicesDefinitions.find(serviceDefinition => serviceDefinition.name === serviceName)

    return service ? service.url.split('/').pop() : serviceName
}

export const isProduction = () => process.env.NODE_ENV === 'production'
export const isDevelopment = () => process.env.NODE_ENV === 'development'
export const isTesting = () => process.env.NODE_ENV === 'test'

export const isNumeric = val => !Number.isNaN(Number.parseFloat(val)) && isFinite(val)

const moneyFormatter = (locale, style, currency, minimumFractionDigits) =>
    new Intl.NumberFormat(locale, {
        style,
        currency,
        minimumFractionDigits,
        // the default value for minimumFractionDigits depends on the currency and is usually already 2
    })

export const dollarFormatter = moneyFormatter('en-US', 'currency', 'USD', 2)

export const percentFormatter = ({ value }) => (isNumeric(value) ? `${roundValue({ value }).toFixed(2)}%` : '')

export const pathFormatter = ({ value, data }) => (data[NAME] !== null ? value : ' ')

export const calcTotal = values => values.reduce((acc, value) => (value ? acc + Number.parseFloat(value) : acc), 0)

export const amountFormatter = ({ value }) => (isNumeric(value) ? dollarFormatter.format(value) : value)

export const fromTimeValueToESTString = timeValue => moment(timeValue).format('DD-MMM-YYYY, h:mm:ss a')

export const roundValue = ({ value, roundTo = DECIMAL_DIGITS_COUNT }) => {
    if (value != null) {
        const floatingNumber = value.toString().split('.')[1]

        return floatingNumber && floatingNumber.length > roundTo && isNumeric(value)
            ? Math.round(value * 10 ** roundTo) / 10 ** roundTo
            : isNumeric(value)
              ? Number(value)
              : value
    }

    return value
}

export const uniqueId = (): string => Math.random().toString(36).substr(2, 8)

export const camelCaseToSentenceCaseText = (str: string): string => {
    const regex = str.replace(/([A-Z])/g, ' $1')

    return regex.charAt(0).toUpperCase() + regex.slice(1)
}

export const createNotification = ({
    id,
    title,
    message,
    kind = NOTIFICATION_TYPE_INFO,
    autoHide = true,
    hideable = true,
    position = 'tr',
}: {
    id?: string | number
    title?: string
    message?: string | JSX.Element
    kind?: AlertKind
    autoHide?: boolean
    hideable?: boolean
    position?: 'tr' | 'tl' | 'tc' | 'br' | 'bl' | 'bc'
    onAdd?: CallBackFunction
    onRemove?: CallBackFunction
}) => {
    NotificationManager.add({
        id,
        kind,
        message: (
            <>
                {title} <p>{message}</p>
            </>
        ),
        autoHide,
        hideable,
        position,
    })
}

export const removeNotification = ({ id }: { id: string }) => NotificationManager.remove(id)

export const uuid = () =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        // tslint:disable-next-line:no-bitwise
        const r = (Math.random() * 16) | 0
        // tslint:disable-next-line:no-bitwise
        const v = c === 'x' ? r : (r & 0x3) | 0x8

        return v.toString(16)
    })

export const isIos = () => {
    const userAgent = window.navigator.userAgent
    return Boolean(userAgent.match(/iPhone|iPad|iPod/i))
}

export const isMobile = () => {
    const userAgent = window.navigator.userAgent
    const isAndroid = Boolean(userAgent.match(/Android/i))
    const isOpera = Boolean(userAgent.match(/Opera Mini/i))
    const isWindows = Boolean(userAgent.match(/IEMobile/i))

    return Boolean(isAndroid || isIos() || isOpera || isWindows)
}

export const isPortrait = () => {
    return Boolean(window.innerHeight > window.innerWidth)
}

export const dateComparator = (dateStr1, dateStr2) => {
    const date1 = moment(dateStr1, [DATE_FORMAT, TIME_STAMP_FORMAT])
    const date2 = moment(dateStr2, [DATE_FORMAT, TIME_STAMP_FORMAT])

    return date1.diff(date2)
}
