/* eslint-env browser */
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import loading from '~reducers/loading'
import entitlements from '~reducers/navigation'
import book from '~reducers/book'
import contacts from '~reducers/contacts'
import { rootSaga } from '~saga'

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
    }
}

export const history = createBrowserHistory()

export const startStore = () => {
    const sagaMiddleware = createSagaMiddleware()

    const middlewares = [sagaMiddleware, routerMiddleware(history)]

    if (process.env.REDUX_LOGGER) {
        middlewares.push(logger)
    }

    const store = configureStore({
        reducer: {
            router: connectRouter<any>(history),
            loading,
            entitlements,
            book,
            contacts,
        },
        middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middlewares),
        devTools: Boolean(window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__),
    })

    sagaMiddleware.run(rootSaga)

    return store
}
