import { RootState } from '~reducers/index'
import { createSelector } from 'reselect'
import { createReducer } from 'deox'
import { resetStoreAction } from '~actions'
import { setClearContactFormAction } from '~actions/contacts'

export interface ContactsState {
    shouldClearFormState: boolean
}

export const initialState: ContactsState = { shouldClearFormState: false }

export const contactsReducer = createReducer(initialState, handle => [
    handle(setClearContactFormAction, (state, { payload: { shouldClearFormState } }): ContactsState => {
        return { ...state, shouldClearFormState }
    }),
    handle(resetStoreAction, (): ContactsState => {
        return initialState
    }),
])

export default contactsReducer

const contactsStateSelector = (state: RootState) => state.contacts

export const shouldClearFormStateSelector = createSelector(contactsStateSelector, state => state.shouldClearFormState)
