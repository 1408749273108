import React, { Children } from 'react'
import classNames from 'classnames'
import {
    Button as ComponentsButton,
    ButtonProps as ComponentsButtonProps,
    Spinner,
    UncontrolledTooltip,
} from 'reactstrap'

export interface ButtonProps extends ComponentsButtonProps {
    disabledReason?: React.JSX.Element | string
    tooltipId?: string
}

export const Button = React.memo((props: ButtonProps) => {
    const {
        tooltipId,
        children,
        onClick,
        disabled,
        className,
        title,
        isLoading,
        style,
        disabledReason,
        buttonRef,
        color,
        size,
        ...rest
    } = props

    const buttonProps: ButtonProps = {
        className: classNames(className, { 'btn-loading': isLoading }),
        title,
        style,
        color,
        size: size !== 'none' ? size || 'sm' : undefined,
        disabled,
        innerRef: buttonRef,
        onClick: event => event,
    }

    if (!buttonProps.disabled) {
        buttonProps.onClick = onClick
    }
    const renderButton = () => (
        <ComponentsButton {...{ ...rest, ...buttonProps }}>
            {isLoading ? (
                <span style={{ position: 'relative' }}>
                    <span style={{ position: 'absolute' }}>
                        <Spinner />
                    </span>
                    <span className="invisible">{children}</span>
                </span>
            ) : (
                children && (
                    <span className={classNames({ 'two-items': Children.toArray(children).length > 1 })}>
                        {children}
                    </span>
                )
            )}
        </ComponentsButton>
    )

    return tooltipId && disabledReason ? (
        <span id={tooltipId}>
            {renderButton()}
            {disabled && <UncontrolledTooltip target={tooltipId}>{disabledReason}</UncontrolledTooltip>}
        </span>
    ) : (
        renderButton()
    )
})
