export const FETCH_TRANSLATED_TEXTS_SERVICE = 'fetchTranslatedTextsService'
export const FETCH_UNAVAILABLE_DATES_SERVICE = 'fetchUnavalilableDatesService'
export const SUBMIT_CONTACT_FORM_SERVICE = 'submitContactFormService'
export const SUBMIT_BOOKING_FORM_SERVICE = 'submitBookingFormService'
export const EASYPAY_TEST_CHECKOUT_SERVICE = 'easyPayTestCheckoutService'
export const EASYPAY_PROD_CHECKOUT_SERVICE = 'easyPayProdCheckoutService'

// loaders
export const FETCH_TRANSLATED_TEXTS_LOADER = 'FETCH_TRANSLATED_TEXTS_LOADER'
export const FETCH_UNAVAILABLE_DATES_LOADER = 'FETCH_UNAVAILABLE_DATES_LOADER'
export const SUBMIT_CONTACT_FORM_LOADER = 'SUBMIT_CONTACT_FORM_LOADER'
export const SUBMIT_BOOKING_FORM_LOADER = 'SUBMIT_BOOKING_FORM_LOADER'
export const EASYPAY_PAYMENT_LOADER = 'EASYPAY_PAYMENT_LOADER'

export const HOME_ROUTE = '/home'
export const BOOK_ROUTE = '/book'
export const ABOUT_ROUTE = '/about'
export const FAQ_ROUTE = '/faq'
export const TERMS_ROUTE = '/terms'
export const PRIVACY_ROUTE = '/privacy'
export const CONTACTS_ROUTE = '/contacts'
export const BASE_ROUTE = '/'
export const EMPTY_ROUTE = '/empty'
