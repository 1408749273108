import { push } from 'connected-react-router'
import { all, takeLatest, put, select, call } from 'redux-saga/effects'
import {
    fetchTranslatedTextsAction,
    redirectToPathAction,
    RedirectToPathActionType,
    setTranslatedTextsAction,
} from '~actions/navigation'
import { selectedLanguageSelector } from '~reducers/navigation'
import { startLoading, stopLoading } from '~actions/loading'
import { FETCH_TRANSLATED_TEXTS_LOADER, FETCH_TRANSLATED_TEXTS_SERVICE } from '~constants/api'
import { services } from '~utils/services'
import { handleSagaError } from '~saga/utils'

function* onRedirectToPath({ payload: { pathname } }: RedirectToPathActionType) {
    const selectedLanguage = yield select(selectedLanguageSelector)
    yield put(push(`${pathname}#${selectedLanguage.value}`))
}

function* onTranslatedText() {
    try {
        yield put(startLoading({ loader: FETCH_TRANSLATED_TEXTS_LOADER }))
        const response = yield call(services[FETCH_TRANSLATED_TEXTS_SERVICE])

        yield put(setTranslatedTextsAction(response))
    } catch (error: any) {
        yield call(handleSagaError, { error, title: FETCH_TRANSLATED_TEXTS_SERVICE })
    } finally {
        yield put(stopLoading({ loader: FETCH_TRANSLATED_TEXTS_LOADER }))
    }
}

export function* navigationSaga() {
    yield all([
        takeLatest(redirectToPathAction, onRedirectToPath),
        takeLatest(fetchTranslatedTextsAction, onTranslatedText),
    ])
}
