import { set } from 'timm'
import { createReducer } from 'deox'
import { createSelector } from 'reselect'
import { startLoading, stopLoading } from '~actions/loading'
import { RootState } from '~reducers/index'

export interface LoadingState {
    [key: string]: boolean
}

// initial state for loading
const initialState: LoadingState = {}

// a reducer is a pure function that takes state and action and returns new state without mutating the old one
export const loadingReducer = createReducer(initialState, handle => [
    handle(startLoading, (state, { payload: { loader } }): LoadingState => {
        return set(state, loader, true)
    }),
    handle(stopLoading, (state, { payload: { loader } }): LoadingState => {
        return set(state, loader, false)
    }),
])

export default loadingReducer

export const loadingState = (rootState: RootState) => rootState.loading

export const isLoadingSelector = (loader: string) => createSelector(loadingState, state => Boolean(state[loader]))

export const isAnythingLoadingSelector = createSelector(loadingState, state =>
    Object.values(state).some(loadingValue => loadingValue === true)
)
