import React from 'react'
import { i18n } from '~utils/i18n'

export const Faq = () => {
    return (
        <div className="px-3 py-4 text-primary">
            <p>{i18n({ key: 'faq-paragraph-1' })}</p>
            <p>{i18n({ key: 'faq-paragraph-2' })}</p>
            <div>{i18n({ key: 'faq-text-1' })}</div>
            <div>{i18n({ key: 'faq-text-2' })}</div>
            <div>{i18n({ key: 'faq-text-3' })}</div>
            <div>{i18n({ key: 'faq-text-4' })}</div>
            <div>{i18n({ key: 'faq-text-5' })}</div>
            <div>{i18n({ key: 'faq-text-6' })}</div>
            <div>{i18n({ key: 'faq-text-7' })}</div>
            <div>{i18n({ key: 'faq-text-8' })}</div>
            <div>{i18n({ key: 'faq-text-9' })}</div>
            <div>{i18n({ key: 'faq-text-10' })}</div>
            <div>{i18n({ key: 'faq-text-11' })}</div>
            <div>{i18n({ key: 'faq-text-12' })}</div>
            <div>{i18n({ key: 'faq-text-13' })}</div>
            <div>{i18n({ key: 'faq-text-14' })}</div>
            <div>{i18n({ key: 'faq-text-15' })}</div>
            <div>{i18n({ key: 'faq-text-16' })}</div>
            <div>{i18n({ key: 'faq-text-17' })}</div>
            <div>{i18n({ key: 'faq-text-18' })}</div>
            <div>{i18n({ key: 'faq-text-19' })}</div>
            <div>{i18n({ key: 'faq-text-20' })}</div>
        </div>
    )
}
