/* eslint-env browser */
import React from 'react'
import { Root } from '~components/Root'
import '../styles/index.scss'
import { ModuleRegistry } from '@ag-grid-community/core'
import { LicenseManager } from '@ag-grid-enterprise/core'
import { createRoot } from 'react-dom/client'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { SideBarModule } from '@ag-grid-enterprise/side-bar'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail'

declare const window: {
    VERSION: any
    NODE_VERSION: any
    NODE_ENV: any
    BABEL_ENV: any
    BUILD_TIME: any
}

const container = document.getElementById('app')
const root = createRoot(container!)

LicenseManager.setLicenseKey(process.env.AG_GRID_LICENSE!)

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    SideBarModule,
    FiltersToolPanelModule,
    RowGroupingModule,
    ColumnsToolPanelModule,
    MasterDetailModule,
])

root.render(<Root />)

// noinspection JSUnresolvedVariable
window.VERSION = process.env.APP_VERSION
window.NODE_VERSION = process.env.NODE_VERSION
window.NODE_ENV = process.env.NODE_ENV
window.BABEL_ENV = process.env.BABEL_ENV
window.BUILD_TIME = process.env.BUILD_TIME
