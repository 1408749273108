import * as fromApi from '~constants/api'

export interface ServiceFields {
    method?: string
    name: string
    url: string
    //url: string | ((params: { [key: string]: string | number }) => string)
    schema: object | boolean
    headers?: { [key: string]: string }
}

const serviceDescriptor: ServiceFields[] = [
    {
        name: fromApi.SUBMIT_CONTACT_FORM_SERVICE,
        url: 'bots/BA-rentals/Contacts.php',
        schema: {},
    },
    {
        name: fromApi.SUBMIT_BOOKING_FORM_SERVICE,
        url: 'bots/BA-rentals/Booking.php',
        schema: {},
    },
    {
        method: 'GET',
        name: fromApi.FETCH_UNAVAILABLE_DATES_SERVICE,
        url: 'bots/BA-rentals/CheckAvailability.php',
        schema: {},
    },
    {
        method: 'GET',
        name: fromApi.FETCH_TRANSLATED_TEXTS_SERVICE,
        url: 'bots/BA-rentals/TranslatedTexts.php',
        schema: {},
    },
    {
        name: fromApi.EASYPAY_TEST_CHECKOUT_SERVICE,
        url: `https://api.test.easypay.pt/2.0/checkout`,
        headers: { AccountId: '2b0f63e2-9fb5-4e52-aca0-b4bf0339bbe6', ApiKey: 'eae4aa59-8e5b-4ec2-887d-b02768481a92' },
        schema: {},
    },
    {
        name: fromApi.EASYPAY_PROD_CHECKOUT_SERVICE,
        url: `https://api.prod.easypay.pt/2.0/checkout`,
        headers: { AccountId: '', ApiKey: '' }, //TODO TESTING add credentials
        schema: {},
    },
]

const defaultServiceConfig = {
    method: 'POST',
    credentials: 'same-origin',
    contentType: 'application/json',
    accept: 'application/json',
}

export const servicesDefinitions = serviceDescriptor.map(serviceConfig => ({
    ...defaultServiceConfig,
    ...serviceConfig,
}))
