import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch, withRouter } from 'react-router'
import { Container } from 'reactstrap'
// @ts-ignore
import appIcon from '../../favicon.png'
import { NotificationContainer } from '~containers'
import { Footer, NotFound, Spinner } from '~components/shared'
import { NavigationBar } from '~components/NavigationBar'
import { Home } from '~components/Home'
import {
    HOME_ROUTE,
    EMPTY_ROUTE,
    BOOK_ROUTE,
    ABOUT_ROUTE,
    FAQ_ROUTE,
    TERMS_ROUTE,
    PRIVACY_ROUTE,
    CONTACTS_ROUTE,
} from '~constants/api'
import { i18n } from '~utils/i18n'
import { selectedLanguageSelector } from '~reducers/navigation'
import { fetchTranslatedTextsAction, redirectToPathAction } from '~actions/navigation'
import { Book } from '~components/Book'
import { About } from '~components/About'
import { Contacts } from '~components/Contacts'
import { Terms } from '~components/Terms'
import { Faq } from '~components/Faq'
import { setLanguageAction } from '~actions/navigation'
import { PrivacyPolicy } from '~components/PrivacyPolicy'

export const getLanguageFromHash = hash => {
    switch (hash) {
        case '#ru':
            return { label: 'РУ', value: 'ru' }
        case '#bg':
            return { label: 'БГ', value: 'bg' }
        case '#en':
        default:
            return { label: 'EN', value: 'en' }
    }
}

export const _App = ({ location: { pathname, hash } }) => {
    const dispatch = useDispatch()
    const isUserAuthenticated = true // useSelector(isUserAuthenticatedSelector)
    const isUserAuthenticating = false // useSelector(isLoadingSelector(USER_AUTHENTICATING))
    const selectedLanguage = useSelector(selectedLanguageSelector)

    React.useEffect(() => {
        dispatch(fetchTranslatedTextsAction())
        dispatch(setLanguageAction({ lang: getLanguageFromHash(hash) }))
    }, [])

    React.useEffect(() => {
        dispatch(redirectToPathAction({ pathname: EMPTY_ROUTE }))
        setTimeout(() => {
            dispatch(redirectToPathAction({ pathname }))
        }, 0)
    }, [selectedLanguage.value])

    return (
        <>
            <NotificationContainer />
            <Container fluid className="main-app ps-0 pe-0">
                {!isUserAuthenticating && isUserAuthenticated ? (
                    <NavigationBar
                        headerTitle={
                            <div>
                                {/* <span>
                                    <img width="28px" height="28px" src={appIcon} className="mr-1" />
                                    <GiDiamondHard className="mr-1" size="1.5em" />
                                </span> */}
                                <span>{i18n({ key: 'app-title' })}</span>
                            </div>
                        }
                    />
                ) : null}
                <div className="main-app-body position-relative">
                    {isUserAuthenticating ? <Spinner size="lg" /> : null}
                    <Switch>
                        <Redirect exact from={'/'} to={`${HOME_ROUTE}#${selectedLanguage.value}`} />
                        <Route path={`${HOME_ROUTE}`} component={Home} exact />
                        <Route path={`${BOOK_ROUTE}`} component={Book} exact />
                        <Route path={`${ABOUT_ROUTE}`} component={About} exact />
                        <Route path={`${FAQ_ROUTE}`} component={Faq} exact />
                        <Route path={`${TERMS_ROUTE}`} component={Terms} exact />
                        <Route path={`${PRIVACY_ROUTE}`} component={PrivacyPolicy} exact />
                        <Route path={`${CONTACTS_ROUTE}`} component={Contacts} exact />
                        <Route path={`${EMPTY_ROUTE}`} component={() => <div />} />
                        <Route path="*" component={NotFound} />
                    </Switch>
                </div>
                <Footer isUserAuthenticated={isUserAuthenticated} />
            </Container>
        </>
    )
}

export const App = withRouter(_App)
