import React from 'react'
import { Row, Col, Form, FormGroup, Label, Input, InputGroup } from 'reactstrap'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import Calendar from 'react-calendar'
import Select from 'react-select'
import moment from 'moment'
import { bookCamperAction, checkAvailabilityAction, setClearBookFormAction } from '~actions/book'
import { Button } from '~components/shared'
import { OptionListFields, RANGE_DATE_FORMAT } from '~constants/report'
import { selectedLanguageSelector } from '~reducers/navigation'
import { useWindowSize } from '~utils/useWindowSize'
import { i18n } from '~utils/i18n'
import { listOfDisabledDatesSelector, shouldClearFormStateSelector } from '~reducers/book'
import { Link } from 'react-router-dom'
import { PRIVACY_ROUTE } from '~constants/api'
import { isPortrait } from '~utils/utils'

type CalendarValuePiece = Date | null

export type CalendarValue = CalendarValuePiece | [CalendarValuePiece, CalendarValuePiece]

export const Book = () => {
    const dispatch = useDispatch()
    const shouldClearFormState = useSelector(shouldClearFormStateSelector)
    const width = useWindowSize().width
    const isSmallScreen = width && width < 1180
    const { value: lang } = useSelector(selectedLanguageSelector)
    const listOfDisabledDates = useSelector(listOfDisabledDatesSelector)
    const selectedLanguage = useSelector(selectedLanguageSelector)
    const [selectedDate, setSelectedDate] = React.useState<CalendarValue>()
    const [occupancy, setOccupancy] = React.useState<OptionListFields | undefined>(undefined)
    const [boutiqueOption, setBoutiqueOption] = React.useState<OptionListFields | undefined>(undefined)
    const [destination, setDestination] = React.useState('')
    const [fullName, setFullName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [phoneCode, setPhoneCode] = React.useState('')
    const [phoneNumber, setPhoneNumber] = React.useState('')
    const [additionalRequirements, setAdditionalRequirements] = React.useState('')
    const [checkbox, setCheckbox] = React.useState(false)
    const [isFullNameValid, setIsFullNameValid] = React.useState(true)
    const [isEmailValid, setIsEmailValid] = React.useState(true)
    const [isPhoneCodeValid, setIsPhoneCodeValid] = React.useState(true)
    const [isPhoneNumberValid, setIsPhoneNumberValid] = React.useState(true)
    const [paymentProvider, setPaymentProvider] = React.useState('')
    const occupancyOptions: OptionListFields[] = [
        { label: `2 ${i18n({ key: 'guests-text' })}`, value: '2' },
        { label: `3 ${i18n({ key: 'guests-text' })}`, value: '3' },
        { label: `4 ${i18n({ key: 'guests-text' })}`, value: '4' },
    ]
    const boutiqueOptions: OptionListFields[] =
        i18n({ key: 'about-left-numbers-2' })?.map(item => ({
            label: item.replace(/[^a-zA-Zа-яА-Я\s]/g, ''),
            value: item.replace(/[^a-zA-Zа-яА-Я\s]/g, ''),
        })) || []

    const disableBookedDates = ({ date }) =>
        listOfDisabledDates
            ? listOfDisabledDates.some(range => moment(date).isBetween(range[0], range[1], undefined, '[]'))
            : false

    const isDateValid =
        Array.isArray(selectedDate) &&
        selectedDate[0] &&
        selectedDate[1] &&
        listOfDisabledDates?.every(
            range =>
                !moment(range[0]).isBetween(
                    moment(selectedDate[0]).format(RANGE_DATE_FORMAT),
                    moment(selectedDate[1]).format(RANGE_DATE_FORMAT)
                )
        )

    const isFormValid =
        occupancy &&
        occupancy.value &&
        boutiqueOption &&
        boutiqueOption.value &&
        destination &&
        isFullNameValid &&
        fullName &&
        isEmailValid &&
        email &&
        phoneCode &&
        isPhoneCodeValid &&
        phoneNumber &&
        isPhoneNumberValid &&
        checkbox &&
        isDateValid // &&
    // paymentProvider

    const onSubmit = () => {
        if (isFormValid) {
            dispatch(
                bookCamperAction({
                    caravanId: '1',
                    occupancy: occupancy!,
                    boutiqueOption: boutiqueOption!,
                    destination,
                    selectedDate: [
                        moment(selectedDate[0]).add(10, 'hours').toDate(),
                        moment(selectedDate[1]).add(-5, 'hours').add(1, 'ms').toDate(),
                    ],
                    fullName,
                    email,
                    phoneCode,
                    phoneNumber,
                    additionalRequirements,
                    paymentProvider,
                })
            )
        }
    }
    React.useEffect(() => {
        dispatch(checkAvailabilityAction())
    }, [])

    // select first available date
    // React.useEffect(() => {
    //     let date = moment().startOf('day')
    //     let isInvalid = true
    //     while (isInvalid) {
    //         isInvalid = listOfDisabledDates
    //             ? listOfDisabledDates.some(range => date.isBetween(range[0], range[1], undefined, '[]'))
    //             : false
    //
    //         if (isInvalid) {
    //             date = date.add(1, 'days')
    //         } else {
    //             console.log(1)
    //             setSelectedDate(date.toDate())
    //         }
    //     }
    // }, [listOfDisabledDates])

    React.useEffect(() => {
        if (shouldClearFormState) {
            setSelectedDate(null)
            setOccupancy('' as any)
            setBoutiqueOption('' as any)
            setDestination('')
            setFullName('')
            setEmail('')
            setPhoneCode('')
            setPhoneNumber('')
            setAdditionalRequirements('')
            setCheckbox(false)
            setIsFullNameValid(true)
            setIsEmailValid(true)
            setIsPhoneCodeValid(true)
            setIsPhoneNumberValid(true)
            setPaymentProvider('')
            dispatch(setClearBookFormAction({ shouldClearFormState: false }))
        }
    }, [shouldClearFormState])

    return (
        <div className="px-5 py-4">
            <Row className={classNames({ 'flex-column': isPortrait() })}>
                <Col md={6}>
                    <Calendar
                        className={classNames({ 'vertical-layout': isSmallScreen })}
                        selectRange
                        showDoubleView
                        locale={lang}
                        minDate={new Date()}
                        showNeighboringMonth={false}
                        showFixedNumberOfWeeks={false}
                        goToRangeStartOnSelect={false}
                        onChange={setSelectedDate}
                        value={selectedDate}
                        tileDisabled={disableBookedDates}
                        formatMonthYear={(local, date) => moment(date, local).format('MMM YY')}
                    />
                </Col>
                <Col md={6}>
                    <Form onSubmit={onSubmit}>
                        <div className="d-flex justify-content-between">
                            <FormGroup className="form-group required" style={{ width: '45%' }}>
                                <Label for="occupancy">{i18n({ key: 'occupancy-label' })}</Label>
                                <Select
                                    inputId="occupancy"
                                    classNamePrefix="react-select"
                                    options={occupancyOptions as any}
                                    name="occupancy"
                                    // placeholder={`2 - 4 ${i18n({ key: 'guests-text' })}`}
                                    // components={{ ValueContainer: SelectValueContainer(label || '') }}
                                    maxMenuHeight={250}
                                    value={occupancy}
                                    onChange={value => {
                                        if (value != null) {
                                            setOccupancy(value)
                                        }
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="form-group required" style={{ width: '45%' }}>
                                <Label for="destination">{i18n({ key: 'destination-label' })}</Label>
                                <Input
                                    id="destination"
                                    name="destination"
                                    required
                                    type="text"
                                    value={destination}
                                    onChange={({ target: { value } }) => setDestination(value)}
                                />
                            </FormGroup>
                        </div>
                        <FormGroup className="form-group required">
                            <Label for="boutique-options">{i18n({ key: 'boutique-options-label' })}</Label>
                            <Select
                                inputId="boutique-options"
                                classNamePrefix="react-select"
                                options={boutiqueOptions as any}
                                name="boutique-options"
                                maxMenuHeight={250}
                                value={boutiqueOption}
                                onChange={value => {
                                    if (value != null) {
                                        setBoutiqueOption(value)
                                    }
                                }}
                            />
                        </FormGroup>
                        <FormGroup className="form-group required">
                            <Label for="fullName">{i18n({ key: 'full-name-label' })}</Label>
                            <Input
                                id="fullName"
                                name="fullName"
                                required
                                type="text"
                                invalid={!isFullNameValid && !!fullName}
                                onBlur={() => setIsFullNameValid(Boolean(fullName.match(/^(\w.+\s).+$/)))}
                                value={fullName}
                                onChange={({ target: { value } }) => {
                                    setFullName(value)
                                    if (!isFullNameValid) {
                                        setIsFullNameValid(Boolean(value.match(/^(\w.+\s).+$/)))
                                    }
                                }}
                            />
                        </FormGroup>
                        <FormGroup className="form-group required">
                            <Label for="email">{i18n({ key: 'email-label' })}</Label>
                            <Input
                                id="email"
                                name="email"
                                required
                                type="email"
                                invalid={!isEmailValid && !!email}
                                onBlur={() => setIsEmailValid(Boolean(email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)))}
                                value={email}
                                onChange={({ target: { value } }) => {
                                    setEmail(value)
                                    if (!isEmailValid) {
                                        setIsEmailValid(Boolean(value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)))
                                    }
                                }}
                            />
                        </FormGroup>
                        <FormGroup className="form-group required">
                            <Label for="phoneNumber">{i18n({ key: 'phone-number-label' })}</Label>
                            <InputGroup>
                                <Input
                                    id="phoneCode"
                                    name="phoneCode"
                                    placeholder={i18n({ key: 'phone-code-placeholder' })}
                                    required
                                    type="text"
                                    value={phoneCode}
                                    invalid={!isPhoneCodeValid && !!phoneCode}
                                    onBlur={() => setIsPhoneCodeValid(Boolean(phoneCode.match(/^\+\d{1,4}$/)))}
                                    onChange={({ target: { value } }) => {
                                        setPhoneCode(value)
                                        if (!isPhoneCodeValid) {
                                            setIsPhoneCodeValid(Boolean(value.match(/^\+\d{1,4}$/)))
                                        }
                                    }}
                                    style={{ maxWidth: '100px' }}
                                />
                                <Input
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    placeholder={i18n({ key: 'phone-number-placeholder' })}
                                    required
                                    type="tel"
                                    value={phoneNumber}
                                    invalid={!isPhoneNumberValid && !!phoneNumber}
                                    onBlur={() => setIsPhoneNumberValid(Boolean(phoneNumber.match(/^[0-9]*$/)))}
                                    onChange={({ target: { value } }) => {
                                        setPhoneNumber(value)
                                        if (!isPhoneNumberValid) {
                                            setIsPhoneNumberValid(Boolean(value.match(/^[0-9]*$/)))
                                        }
                                    }}
                                />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className="form-group">
                            <Label for="additional-requirements">{i18n({ key: 'additional-requirements' })}</Label>
                            <Input
                                id="additional-requirements"
                                name="additional-requirements"
                                type="textarea"
                                value={additionalRequirements}
                                onChange={({ target: { value } }) => setAdditionalRequirements(value)}
                            />
                        </FormGroup>
                        <FormGroup className="form-group required">
                            <Input
                                id="agree-terms"
                                name="agree-terms"
                                required
                                type="checkbox"
                                checked={checkbox}
                                onChange={({ target: { checked } }) => setCheckbox(checked)}
                            />
                            <Label for="agree-terms" className="ps-2 start">
                                {i18n({ key: 'agree-terms-label' })}
                                <Link className="ps-1" to={`${PRIVACY_ROUTE}#${selectedLanguage.value}`}>
                                    {i18n({ key: 'terms-text' })}
                                </Link>
                            </Label>
                        </FormGroup>
                        {/* <FormGroup className="form-group d-flex required">
                            <Label>{i18n({ key: 'payment-provider' })}</Label>
                            <FormGroup check className="ps-5">
                                <Input
                                    id="revolut"
                                    name="revolut"
                                    type="radio"
                                    checked={paymentProvider === 'revolut'}
                                    onChange={() => setPaymentProvider('revolut')}
                                />
                                <Label for="revolut" className="ps-2 start">
                                    Revolut
                                </Label>
                            </FormGroup>
                            <FormGroup check className="ps-5">
                                <Input
                                    id="easypay"
                                    name="easypay"
                                    type="radio"
                                    checked={paymentProvider === 'easypay'}
                                    onChange={() => setPaymentProvider('easypay')}
                                />
                                <Label for="easypay" className="ps-2 start">
                                    EasyPay
                                </Label>
                            </FormGroup>
                        </FormGroup> */}
                        <div className="d-flex justify-content-center">
                            <Button
                                tooltipId="book-button-id"
                                size="none"
                                color="primary"
                                style={{ padding: '10px 50px' }}
                                disabled={!isFormValid}
                                disabledReason={
                                    isDateValid
                                        ? i18n({ key: 'required-fields-disable-reason' })
                                        : selectedDate
                                          ? i18n({ key: 'unavailable-period-disable-reason' })
                                          : i18n({ key: 'no-selected-date-disable-reason' })
                                }
                                onClick={onSubmit}
                            >
                                {i18n({ key: 'book-button' })}
                            </Button>
                            <Button id="easypay-checkout" className="d-none">
                                Easy Pay
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}
