import React from 'react'
import { Row, Col } from 'reactstrap'
import { i18n } from '~utils/i18n'
//@ts-ignore
import image from '../../images/IMG_2254.jpg'
import classNames from 'classnames'
import { isPortrait } from '~utils/utils'

export const Terms = () => {
    return (
        <Row noGutters className={classNames('mt-5 mb-4', { 'flex-column': isPortrait() })}>
            <Col className="px-3 pb-3" md={6}>
                <img width="100%" src={image} />
            </Col>
            <Col className="px-3 text-primary" md={6}>
                <h3 className="header">{i18n({ key: 'terms-header' })}</h3>
                <h4>{i18n({ key: 'terms-paragraph-header1' })}</h4>
                <div>{i18n({ key: 'terms-text-1' })}</div>
                <div>{i18n({ key: 'terms-text-2' })}</div>
                <div>{i18n({ key: 'terms-text-3' })}</div>
                <div className="mb-4">{i18n({ key: 'terms-text-4' })}</div>
                <h4>{i18n({ key: 'terms-paragraph-header2' })}</h4>
                <div>{i18n({ key: 'terms-text-5' })}</div>
                <div>{i18n({ key: 'terms-text-6' })}</div>
                <div>{i18n({ key: 'terms-text-7' })}</div>
                <div className="mb-4">{i18n({ key: 'terms-text-8' })}</div>
                <div>{i18n({ key: 'terms-text-9' })}</div>
                <div>{i18n({ key: 'terms-text-10' })}</div>
            </Col>
        </Row>
    )
}
