/* global fetch, Headers */
import { isEmpty } from 'ramda'
import Ajv from 'ajv'
import { ParseGlobalNetworkError, createNotification } from '~utils/utils'
import { NOTIFICATION_TYPE_ERROR, UNKNOWN_NETWORK_ERROR } from '~constants/notification'
import { servicesDefinitions } from './servicesDefs'

const BASE_URL = process.env.API_URL
const BASE_PATH = ''
const JSON_CONTENT_TYPE = 'application/json'
const SPREADSHEET_CONTENT_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

// export for tests
export const successCallback =
    ({ resolve, reject, service: { url, schema } }) =>
    ({ data }) => {
        const ajv = new Ajv()
        const validate = ajv.compile(schema)
        const isValid = validate(data)

        if (isValid) {
            resolve(data)
        } else {
            const { errors } = validate
            // tslint:disable-next-line:no-console
            console.log('Service response validation error in:', url, errors)

            errors!.forEach(error => {
                const { schemaPath, keyword, message } = error

                createNotification({
                    kind: NOTIFICATION_TYPE_ERROR,
                    message: `Service ${keyword} error in JSON format for "${url}", path "${schemaPath}" ${message}`,
                    autoHide: false,
                })
            })

            reject([
                {
                    message: '(For developers) See the DevTools console (press the F12 key) for the complete error',
                },
            ])
        }
    }

// export for tests
export const errorCallback =
    (reject, serviceName) =>
    (error = { response: { status: 200 } }) => {
        if (
            // tslint:disable-next-line
            typeof error.response === 'object' &&
            (Number(error.response.status) === 401 ||
                Number(error.response.status) === 403 ||
                Number(error.response.status) === 503)
        ) {
            window.location.reload()
        } else {
            reject({
                message: ParseGlobalNetworkError({
                    // tslint:disable-next-line
                    errorCode: typeof error.response === 'object' ? error.response.status : UNKNOWN_NETWORK_ERROR,
                    serviceName,
                }),
            })
        }
    }

export const parseResponseObject = (_reject, serviceName) => response => {
    const { headers, status, ok } = response
    const contentType = headers.get('Content-Type')

    if (!ok) {
        return Promise.reject(new Error('Response parse failed!'))
    }

    if (Number(status) === 204) {
        return Promise.resolve({
            statusCode: status,
            contentType,
        })
    }

    if (contentType.includes(JSON_CONTENT_TYPE)) {
        return response.json().then(data => ({
            status,
            contentType,
            serviceName,
            data,
        }))
    } else if (contentType.includes(SPREADSHEET_CONTENT_TYPE)) {
        return response.arrayBuffer().then(data => ({
            status,
            contentType,
            serviceName,
            data,
        }))
    }
}

export const services = servicesDefinitions.reduce((acc, service) => {
    const { name } = service

    acc[name] = (data = {}, url) => {
        const options = {
            method: service.method,
            headers: {
                'Content-Type': service.contentType,
                //Accept: service.accept,
                ...(service.headers || {}),
            },
            credentials: service.credentials,
            // @ts-ignore
            body: isEmpty(data) ? undefined : data instanceof FormData ? data : JSON.stringify(data.body),
        }

        const urlPrefix = service.url.startsWith('http') ? '' : `${BASE_URL + BASE_PATH}/`
        const targetUrl = url ? `${BASE_URL}/${url}` : `${urlPrefix}${service.url}`

        return new Promise((resolve, reject) =>
            // @ts-ignore
            fetch(targetUrl, options)
                .then(parseResponseObject(reject, name))
                .then(successCallback({ resolve, reject, service }))
                .catch(errorCallback(reject, name))
        )
    }

    return acc
}, {})
