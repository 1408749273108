import { RootState } from '~reducers/index'
import { createSelector } from 'reselect'
import { createReducer } from 'deox'
import { resetStoreAction } from '~actions'
import { saveFormDetailsAction, setClearBookFormAction, setUnavailableDatesAction } from '~actions/book'
import { OptionListFields } from '~constants/report'
import { CalendarValue } from '~components/Book'

export interface BookState {
    shouldClearFormState: boolean
    listOfDisabledDatesById?: { [key: string]: [string, string] }
    formDetails?: {
        caravanId: string
        occupancy: OptionListFields
        destination: string
        selectedDate: CalendarValue
        fullName: string
        email: string
        phoneNumber: string
        phoneCode: string
        additionalRequirements: string
        paymentProvider: string
        paid?: boolean
    }
}

export const initialState: BookState = { shouldClearFormState: false }

export const bookReducer = createReducer(initialState, handle => [
    handle(setUnavailableDatesAction, (state, { payload: { listOfDisabledDatesById } }): BookState => {
        return { ...state, listOfDisabledDatesById }
    }),
    handle(saveFormDetailsAction, (state, { payload }): BookState => {
        return { ...state, formDetails: payload }
    }),
    handle(setClearBookFormAction, (state, { payload: { shouldClearFormState } }): BookState => {
        return { ...state, shouldClearFormState }
    }),
    handle(resetStoreAction, (): BookState => {
        return initialState
    }),
])

export default bookReducer

const bookStateSelector = (state: RootState) => state.book

export const listOfDisabledDatesSelector = createSelector(
    bookStateSelector,
    state => state.listOfDisabledDatesById?.['1']
)

export const shouldClearFormStateSelector = createSelector(bookStateSelector, state => state.shouldClearFormState)
export const formDetailsSelector = createSelector(bookStateSelector, state => state.formDetails)
