import React from 'react'
import { Row, Col } from 'reactstrap'
import { i18n } from '~utils/i18n'
import { Button } from '~components/shared'
import { useDispatch } from 'react-redux'
import { redirectToPathAction } from '~actions/navigation'
import { BOOK_ROUTE } from '~constants/api'
// @ts-ignore
import video1 from '../../images/video1.mp4'
import classNames from 'classnames'
import { isPortrait } from '~utils/utils'

export const Home = () => {
    const dispatch = useDispatch()
    return (
        <div>
            <Row noGutters className={classNames('mt-5', { 'flex-column': isPortrait() })}>
                <Col className="px-3 pb-3" md={6}>
                    <video src={video1} controls autoPlay style={{ width: '100%' }} />
                </Col>
                <Col className="px-3 text-primary" md={6}>
                    <h3 className="header pt-0">{i18n({ key: 'home-header' })}</h3>
                    <h5 className="header">{i18n({ key: 'home-subheader' })}</h5>
                    <p>{i18n({ key: 'home-paragraph-1' })}</p>
                    <p>{i18n({ key: 'home-paragraph-2' })}</p>
                    <p>{i18n({ key: 'home-paragraph-3' })}</p>
                </Col>
            </Row>
            <div className="d-flex justify-content-center mt-5 mb-4">
                <Button
                    size="none"
                    color="primary"
                    style={{ padding: '10px 20px' }}
                    onClick={() => dispatch(redirectToPathAction({ pathname: BOOK_ROUTE }))}
                >
                    {i18n({ key: 'check-availability-button' })}
                </Button>
            </div>
        </div>
    )
}
