import React, { PureComponent } from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { startStore, history } from '~store/createStore'
import { App } from '~components/App'

const store = startStore()

export const getStore = () => {
    return store
}

export class Root extends PureComponent {
    render() {
        return (
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <App />
                </ConnectedRouter>
            </Provider>
        )
    }
}
