import { ActionType, createAction } from 'deox'
import { CalendarValue } from '~components/Book'
import { OptionListFields } from '~constants/report'

export const bookCamperAction = createAction(
    'BOOK_CAMPER',
    resolve =>
        (payload: {
            caravanId: string
            occupancy: OptionListFields
            boutiqueOption: OptionListFields
            destination: string
            selectedDate: CalendarValue
            fullName: string
            email: string
            phoneNumber: string
            phoneCode: string
            additionalRequirements: string
            paymentProvider: string
            paid?: boolean
        }) =>
            resolve(payload)
)

export const saveFormDetailsAction = createAction(
    'SAVE_FORM_DETAILS',
    resolve =>
        (payload: {
            caravanId: string
            occupancy: OptionListFields
            destination: string
            selectedDate: CalendarValue
            fullName: string
            email: string
            phoneNumber: string
            phoneCode: string
            additionalRequirements: string
            paymentProvider: string
            paid?: boolean
        }) =>
            resolve(payload)
)

export const setClearBookFormAction = createAction(
    'SET_CLEAR_BOOK_FORM',
    resolve => (payload: { shouldClearFormState: boolean }) => resolve(payload)
)

export const checkAvailabilityAction = createAction('CHECK_AVAILABILITY', resolve => () => resolve())

export const setUnavailableDatesAction = createAction(
    'SET_UNAVAILABLE_DATES',
    resolve => (payload: { listOfDisabledDatesById: { [key: string]: [string, string] } }) => resolve(payload)
)

export const paymentAction = createAction(
    'PAYMENT',
    resolve => (payload: { money: number; customer: any }) => resolve(payload)
)

export type BookCamperActionType = ActionType<typeof bookCamperAction>
export type EasyPayPaymentActionType = ActionType<typeof paymentAction>
