import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const row1 = '░░░404░░░░░▄▄▄▄░░░│▗▝ ▞ ▝ ˄---˄'
const row2 = '░not found░░░▄▄▄▄░░~│ ▞  ▞ ❬.◕‿‿◕.❭’'
const row3 = '░░░:(░░░░░▄▄▄▄░░░░░ `w-w---- w w'

export const NotFound = props => (
    <h1 className="not-found">
        <div>{row1}</div>
        <div>{row2}</div>
        <div>{row3}</div>
        {props.location ? (
            <Link className="not-found-link-to-home-page" to="/">
                Home
            </Link>
        ) : null}
    </h1>
)

NotFound.propTypes = { location: PropTypes.object }
