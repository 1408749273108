import { NOTIFICATION_TYPE_ERROR } from '~constants/notification'
import { createNotification } from '~utils/utils'
import { useSelector } from 'react-redux'
import { translatedTextSelector } from '~reducers/navigation'
import { isEmpty } from 'ramda'

const englishTranslation: { [index: string]: string | string[] } = {
    'app-title': 'VTL rental',
    'home-tab': 'VTL rental',
    'book-tab': 'Book',
    'about-tab': 'About',
    'faq-tab': 'FAQ',
    'terms-tab': 'Terms',
    'privacy-terms-tab': 'Privacy Policy',
    'contacts-tab': 'Contacts',
    'home-header': 'Welcome to VTL Camper Boutique Camper Rentals!',
    'home-subheader': '**Discover your passion for travel with unmatched style and comfort**',
    'home-paragraph-1':
        'At VTL Camper, we believe that travel should be an extraordinary experience. Our campers are designed for those who seek the finest things in life while exploring the beauty of the open road. Escape the ordinary and indulge in a journey where every detail is crafted to make your adventure unique.',
    'home-paragraph-2': 'Start Your Adventure Today!',
    'home-paragraph-3':
        'At VTL Camper, we turn travel into an art form. Whether you are an experienced adventurer or embarking on a new journey, our boutique campers offer an unparalleled travel experience. Contact us today to learn more or start the booking process online and discover the world in style.',
    'check-availability-button': 'Check Availability',
    'occupancy-label': 'Occupancy',
    'guests-text': 'guests',
    'boutique-options-label': 'Boutique option',
    'destination-label': 'Destination',
    'full-name-label': 'Full Name',
    'email-label': 'Email',
    'phone-number-label': 'Phone number',
    'phone-code-placeholder': 'code',
    'phone-number-placeholder': 'number',
    'how-can-help-label': 'How can we help?',
    'additional-requirements': 'Additional requirements',
    'agree-terms-label': 'I agree to the',
    'terms-text': 'Terms of Use',
    'submit-button': 'Submit',
    'book-button': 'Book selected',
    'required-fields-disable-reason': 'Required fields are mandatory.',
    'unavailable-period-disable-reason': 'Some dates in selected period are unavailable.',
    'no-selected-date-disable-reason': 'No period is selected.',
    'success-book-message': "Request is sent successfully, we'll contact with you in 24 hours",
    'success-contact-message': "Contacts are sent successfully, we'll contact with you in 24 hours",
    'payment-provider': 'Payment Provider',
    'about-left-header': 'Basic Package',
    'about-left-paragraph-1':
        'We take responsibility for your comfort. The following is included in the basic package when booking a camper:',
    'about-left-numbers-1': [
        '1- A set of folding table and comfortable chairs;',
        '2- Mat for grass and sand;',
        '3- Set of bed linens;',
        '4- Set of dining and cooking utensils;',
        '5- Supplies.',
    ],
    'about-left-paragraph-2':
        '**Personal Concierge Services**: Our dedicated concierge team is at your service, providing personalised travel itineraries, exclusive destination tips, and 24/7 support. We handle the details so you can focus on making memories.',
    'about-left-paragraph-3':
        '**Eco-Luxury Travel**: Our commitment to sustainability means you can explore responsibly. Our eco-friendly campers feature energy-efficient systems and sustainable materials, allowing you to enjoy luxury with a conscience.',
    'about-left-paragraph-4':
        '**Seamless Experience**: We make luxury travel effortless. From our easy online booking system to our convenient pick-up and drop-off locations, every step of your journey is designed for your convenience.',
    'about-left-numbers-2': [
        '1- coffee lover;',
        '2- adventure;',
        '3- sunny day;',
        '4- winter person;',
        '5- master grill.',
    ],
    'about-right-header': 'How It Works',
    'about-right-numbers-1': [
        '1. **Choose Your Dream Boutique Option**: Browse our unique collection of package offers and find the perfect match for your adventure.',
        '2. **Effortless Booking**: Secure your reservation through our intuitive online booking platform.',
        '3. **Personalised Orientation**: Pick up your camper and receive comprehensive instruction from our experienced staff.',
        '4. **Travel in Style**: Enjoy the freedom of the open road with the comfort and elegance of a new kind of experience.',
        '5. **Hassle-Free Return**: Return your camper with ease and share your travel experiences with us by leaving your feedback.',
    ],
    'about-right-paragraph-1': '#### Start Your Adventure Today',
    'about-right-paragraph-2':
        'At VTL Camper, we turn travel into an art form. Whether you are an experienced adventurer or embarking on a new journey, our boutique campers offer an unparalleled travel experience. Contact us today to learn more or start the booking process online and discover the world in style.',
    'about-right-paragraph-3': '**Boutique options**',
    'faq-paragraph-1': 'Start your adventure today!',
    'faq-paragraph-2':
        'Contact us and make your next trip unforgettable with our comfortable and convenient rental camper.',
    'faq-text-1':
        '1. What is included in the rental price? - Comfortably accommodates up to 5 people fully stocked kitchen with utensils and cookware; fresh bedding and towels; outdoor seating and awning; heating and air conditioning; bathroom with shower and toilet; ample storage space; supplies; + A bonus boutique option of your choice completely free.',
    'faq-text-2': '2. Is there a minimum rental period? - 2 days.',
    'faq-text-3': '3. How can I book a camper? - Directly through the website.',
    'faq-text-4':
        '4. What is the security deposit, and when will it be refunded? - 2000 BGN (paid in cash upon pick-up, refundable upon return).',
    'faq-text-5': '5. What are the payment options? - Cash upon pick-up, bank transfer, or through the website.',
    'faq-text-6':
        '6. Can I choose the pick-up and drop-off location? - The camper is picked up and returned only at the specified address: 1A Prof. Ivan Georgov St., Cargo Planet Ltd., Military Ramp District, GPS coordinates: 42.735304, 23.330273.',
    'faq-text-7': '7. Is there a mileage limit? - No.',
    'faq-text-8':
        '8. What are the fuel conditions? - The camper is provided with a full tank and must be returned in the same condition.',
    'faq-text-9':
        '9. What happens if I return the camper late? - A charge will be applied for each additional day beyond the agreed period.',
    'faq-text-10': '10. Are pets allowed in the camper? - Yes.',
    'faq-text-11': '11. Can I travel with the camper abroad? - Yes.',
    'faq-text-12':
        '12. What should I do if the camper breaks down during the trip? - Contact us on the company phone and provide a detailed description of the situation.',
    'faq-text-13':
        '13. Are there any additional fees or expenses? - If any damage is found upon returning the camper, the cost will be deducted from the deposit.',
    'faq-text-14':
        '14. What is the cancellation policy? - Cancellations made up to 7 days before the reservation date will incur a 30% cancellation fee.',
    'faq-text-15': '15. Is insurance included in the rental? - Yes.',
    'faq-text-16':
        "16. What documents do I need to present when picking up the camper? - A valid driver's license and an ID card.",
    'faq-text-17':
        '17. Are there any age restrictions for renting a camper? - The driver must have a valid B category driving license.',
    'faq-text-18':
        '18. How should I clean the camper before returning it? - A cleaning fee of 100 BGN is applied with each reservation and is deducted from the deposit.',
    'faq-text-19': '19. What should I do in case of an accident? - Call the company phone and report the incident.',
    'faq-text-20': '20. Can I add additional drivers to the rental agreement? - Yes.',
    'terms-header': 'Rental Duration for Tenants',
    'terms-paragraph-header1': 'High Season',
    'terms-text-1': '01.05-30.09',
    'terms-text-2': '3-7 days: 300 BGN',
    'terms-text-3': '8-14 days: 250 BGN',
    'terms-text-4': '15 days or more: Negotiable',
    'terms-paragraph-header2': 'Low Season',
    'terms-text-5': '01.10-30.04',
    'terms-text-6': '3-7 days: 250 BGN',
    'terms-text-7': '8-14 days: 200 BGN',
    'terms-text-8': '15 days or more: Negotiable',
    'terms-text-9': ' *  100 BGN cleaning fee',
    'terms-text-10': ' *  2000 BGN deposit',

    'privacy-left-header': 'Privacy Policy',
    'privacy-left-numbers-1': [
        'VTL Camper values your privacy and is committed to protecting your personal information.',
        'This Privacy Policy explains how we collect, use, and protect your data.',
    ],
    'privacy-left-numbers-2': [
        '1. Information We Collect',
        '\u2022 Contact Information: Name, email, phone number, address.',
        '\u2022 Identification: Driver’s license, passport information.',
        '\u2022 Payment: Credit card details.',
        '\u2022 Rental Details: History and preferences.',
        '2. How We Use Your Information - Your information is used to:',
        'Provide Services: Process reservations, customer support.',
        '\u2022 Communicate: Send confirmations, updates.',
        '\u2022 Improve Services: Enhance based on feedback.',
        '\u2022 Legal Compliance: Meet legal obligations.',
        '3. Information Sharing - We do not sell your information. We may share it with:',
        '\u2022 Service Providers: For operational support.',
        '\u2022 Legal Authorities: As required by law.',
        '\u2022 Business Transfers: In case of mergers or sales.',
        '4. Data Security',
        '\u2022 We implement measures to protect your information from unauthorized access and use.',
        '5. Your Rights - You can:',
        '\u2022 Access your information.',
        '\u2022 Request corrections.',
        '\u2022 Withdraw consent.',
        '\u2022 Request deletion, subject to legal obligations.',
        '6. Cookies',
        '\u2022 We use cookies to improve your experience. Control these through your browser settings.',
        '7. Policy Changes',
        '\u2022 We may update this policy and will notify you of significant changes by posting on our website.',
        '8. Contact Us',
    ],
    'privacy-left-paragraph-1': 'By using our services, you agree to this Privacy Policy.',
    'privacy-right-header': 'Terms of Use',
    'privacy-right-numbers-1': [
        '1. Use of the Site',
        "You agree to use our site lawfully and without infringing on others' rights. Prohibited actions include harassment, transmitting offensive content, or disrupting the site.",
    ],
    'privacy-right-numbers-2': [
        '2. Intellectual Property',
        'Content on this site is owned by [Company Name] and protected by law. You may not reproduce or distribute any content without permission.',
    ],
    'privacy-right-numbers-3': [
        '3. User Content',
        'By submitting content, you grant [Company Name] a license to use, reproduce, and distribute it. You confirm you have the rights to grant this license.',
    ],
    'privacy-right-numbers-4': [
        '4. Privacy',
        'Your use of our site is governed by our Privacy Policy, available [here/link].',
    ],
    'privacy-right-numbers-5': [
        '5. Disclaimers',
        'Our site is provided "as-is" without warranties. We do not guarantee it will be error-free or uninterrupted.',
    ],
    'privacy-right-numbers-6': [
        '6. Limitation of Liability',
        '[Company Name] is not liable for any damages arising from your use of the site.',
    ],
    'privacy-right-numbers-7': [
        '7. Modifications',
        'We may update these terms. Continued use of the site signifies acceptance of the changes.',
    ],
    'privacy-right-numbers-8': [
        '8. Governing Law',
        'These terms are governed by the laws of [Your Jurisdiction]. Disputes will be resolved in [Your Jurisdiction] courts.',
    ],
    'privacy-right-numbers-9': [
        'Contact Us',
        'For questions, contact us at:',
        '\u2022 Email: [Email Address]',
        '\u2022 Phone: [Phone Number]',
        '\u2022 Address: [Physical Address]',
        'By using our site, you agree to these Terms of Use.',
    ],
    'contacts-paragraph-1': 'Dear Clients,',
    'contacts-paragraph-2':
        'We are pleased to inform you that our team is available 24/7 to assist you with any inquiries or support you may need.',
    'contacts-paragraph-3-new-lines': [
        'You can reach us anytime on the following social media platforms:',
        'Facebook: Vtl Camper',
        'Instagram: vtlcamper',
        'Имейл: vtlcamper@gmail.com',
        'Телефон: 0884996501',
    ],
    'contacts-paragraph-4': 'We look forward your requests!',
    'contacts-paragraph-5': 'Best regards, VTL Camper',
    'footer-instagram-link': 'https://www.instagram.com/vtlcamper?igsh=bmNqMWkzN2x6OHJj',
    'footer-facebook-link': 'https://www.facebook.com/profile.php?id=61564156668001',
    'footer-middle-text': 'Luxury Camper Rentals BA ©',
    'footer-right-text': 'Enabled by Ampla§',
    'footer-right-link': 'https://amplaltd.com',
}

export const i18nUtil = ({
    key,
    translationText,
}: {
    key: string
    translationText: { [key: string]: string | string[] }
}) => {
    const translation = isEmpty(translationText) ? englishTranslation : translationText
    const translatedString = translation[key]

    // if you need an empty string, add the translation as empty string
    if (translatedString == null) {
        createNotification({
            kind: NOTIFICATION_TYPE_ERROR,
            message: `No translation for key: "${key}"`,
        })
    }

    return translatedString as any
}

export const i18n = ({ key }: { key: string }) => {
    const translationText = useSelector(translatedTextSelector)

    return i18nUtil({ key, translationText })
}
