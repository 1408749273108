import React, { createRef, PureComponent, ReactNode, RefObject } from 'react'
import { createPortal } from 'react-dom'
import NotificationSystem, {
    Notification as DefaultNotificationProps,
    CallBackFunction,
} from 'react-notification-system'
import PropTypes from 'prop-types'
import { Alert, AlertKind } from '~components/shared'
import { uniqueId } from '~utils/utils'
import { NOTIFICATION_TYPE_SUCCESS } from '~constants/notification'

// tslint:disable-next-line:no-empty
const emptyFunc = () => {}

export interface NotificationItemProps {
    message?: string | JSX.Element
    position?: 'tr' | 'tl' | 'tc' | 'br' | 'bl' | 'bc'
    onAdd?: CallBackFunction
    onRemove?: CallBackFunction
    kind: AlertKind
    id?: string | number
    hideable?: boolean
    autoHide?: boolean | number
    onClick?: (notification: NotificationItemProps) => void
}

export interface NotificationProps {
    container?: HTMLElement
    children?: ReactNode | ReactNode[]
}

export type IdOrNotification = DefaultNotificationProps | string | number

export interface NotificationManagerProps {
    add: (notification: NotificationItemProps) => DefaultNotificationProps | void
    remove: (notification: IdOrNotification) => void
}

export const NotificationManager: NotificationManagerProps = {
    add: emptyFunc,
    remove: emptyFunc,
}

export class NotificationContainer extends PureComponent<NotificationProps> {
    static propTypes = {
        container: PropTypes.instanceOf(Element),
    }

    readonly notificationSystem: RefObject<NotificationSystem> = createRef()

    constructor(props: NotificationProps) {
        super(props)

        NotificationManager.add = this.add
        NotificationManager.remove = this.remove
    }

    componentWillUnmount() {
        NotificationManager.add = emptyFunc
        NotificationManager.remove = emptyFunc
    }

    style = {
        Containers: {
            DefaultStyle: {
                padding: '0',
                width: 'auto',
            },
            tr: {
                top: '52px',
                bottom: 'auto',
                left: 'auto',
                right: '15px',
            },
        },
        NotificationItem: {
            DefaultStyle: {
                borderTop: 'none',
                borderRadius: '10px',
                boxShadow: 'none',
                transition: 'all 0.4s ease-in-out 0s',
                margin: '0 0 10px 0',
                padding: '0',
            },
        },
    }

    render() {
        const { container } = this.props
        return (
            <>
                {createPortal(
                    <NotificationSystem ref={this.notificationSystem} style={this.style} />,
                    container || document.body
                )}
            </>
        )
    }

    add = (notification: NotificationItemProps) => {
        const notificationItem: DefaultNotificationProps = this.createNotification(notification)
        const current = this.notificationSystem.current
        if (current != null) {
            return current.addNotification(notificationItem)
        }
    }

    remove = (idOrNotification: IdOrNotification) => {
        const current = this.notificationSystem.current
        if (current != null) {
            current.removeNotification(idOrNotification)
        }
    }

    calculateAutoDismiss = (autoHide: boolean | number | undefined, kind) => {
        const defaultAutoHide = kind === NOTIFICATION_TYPE_SUCCESS ? 6 : 8
        if (typeof autoHide === 'boolean') {
            return (autoHide && defaultAutoHide) || 0
        } else {
            return autoHide === undefined ? defaultAutoHide : autoHide
        }
    }

    createNotification = (notification: NotificationItemProps) => {
        const {
            kind,
            message,
            onAdd,
            onRemove,
            onClick,
            hideable = true,
            id = uniqueId(),
            autoHide: autoDismiss,
            ...rest
        } = notification
        const onHide = hideable ? () => this.remove(id) : undefined
        const onClickHandler = onClick
            ? () => {
                  onClick(notification)
                  this.remove(id)
              }
            : undefined
        return {
            ...rest,
            autoDismiss: this.calculateAutoDismiss(autoDismiss, kind),
            children: (
                <Alert style={onClick && { cursor: 'pointer' }} onHide={onHide} kind={kind} show>
                    <div className={'message'} onClick={onClickHandler}>
                        {message}
                    </div>
                </Alert>
            ),
            uid: id,
            dismissible: false,
            level: kind === 'danger' ? 'error' : kind,
            onRemove,
            onAdd,
        } as DefaultNotificationProps
    }
}
