import { resetUserEntitlementsAction, setLanguageAction, setTranslatedTextsAction } from '~actions/navigation'
import { OptionListFields } from '~constants/report'
import { RootState } from '~reducers/index'
import { createSelector } from 'reselect'
import { createReducer } from 'deox'
import { resetStoreAction } from '~actions'

export interface EntitlementsState {
    language: OptionListFields
    englishTranslation: { [key: string]: string | string[] }
    bulgarianTranslation: { [key: string]: string | string[] }
    russianTranslation: { [key: string]: string | string[] }
}

export const initialState: EntitlementsState = {
    language: { label: 'EN', value: 'en' },
    englishTranslation: {},
    bulgarianTranslation: {},
    russianTranslation: {},
}

export const entitlementsReducer = createReducer(initialState, handle => [
    handle(setTranslatedTextsAction, (state, { payload }): EntitlementsState => {
        return { ...state, ...payload }
    }),
    handle(setLanguageAction, (state, { payload: { lang } }): EntitlementsState => {
        return { ...state, language: lang }
    }),
    handle(resetUserEntitlementsAction, (_, { payload: { lang } }): EntitlementsState => {
        return { ...initialState, language: lang }
    }),
    handle(resetStoreAction, (): EntitlementsState => {
        return initialState
    }),
])

export default entitlementsReducer

const entitlementsStateSelector = (state: RootState) => state.entitlements

export const selectedLanguageSelector = createSelector(entitlementsStateSelector, state => state.language)

export const translatedTextSelector = createSelector(
    entitlementsStateSelector,
    selectedLanguageSelector,
    (state, lang) => {
        const language = lang !== undefined ? `#${lang.value}` : location.hash

        switch (language) {
            case '#bg':
                return state.bulgarianTranslation
                break
            case '#ru':
                return state.russianTranslation
                break
            case '#en':
            default:
                return state.englishTranslation
                break
        }
    }
)
