import React from 'react'
import { Row, Col } from 'reactstrap'
import { i18n } from '~utils/i18n'
import classNames from 'classnames'
import { isPortrait } from '~utils/utils'

export const About = () => {
    return (
        <>
            <Row noGutters className={classNames({ 'flex-column': isPortrait() })}>
                <Col className="px-3 mt-3 text-primary" md={6}>
                    <h2 className="header">{i18n({ key: 'about-left-header' })}</h2>
                    <p>{i18n({ key: 'about-left-paragraph-1' })}</p>
                    <p>{i18n({ key: 'about-left-numbers-1' })?.map(item => <div>{item}</div>)}</p>
                    <p>{i18n({ key: 'about-left-paragraph-2' })}</p>
                    <p>{i18n({ key: 'about-left-paragraph-3' })}</p>
                    <p>{i18n({ key: 'about-left-paragraph-4' })}</p>
                </Col>
                <Col className="px-3 mt-3 text-primary" md={6}>
                    <h2 className="header text-primary">{i18n({ key: 'about-right-header' })}</h2>
                    <p>
                        <div>{i18n({ key: 'about-right-numbers-1' })?.[0]} </div>
                        <div>{i18n({ key: 'about-right-paragraph-3' })} </div>
                        {i18n({ key: 'about-left-numbers-2' })?.map(item => <div className="ps-3">{item}</div>)}
                    </p>
                    <p>
                        {i18n({ key: 'about-right-numbers-1' })
                            ?.slice(1)
                            .map(item => <div>{item}</div>)}
                    </p>
                    <p>{i18n({ key: 'about-right-paragraph-1' })}</p>
                    <p>{i18n({ key: 'about-right-paragraph-2' })}</p>
                </Col>
            </Row>
        </>
    )
}
