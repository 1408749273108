import React, { ReactNode, HTMLAttributes } from 'react'
import classNames from 'classnames'
import {
    IoIosCloseCircleOutline,
    IoIosInformationCircleOutline,
    IoMdCheckmarkCircleOutline,
    IoIosWarning,
    IoMdCloseCircle,
} from 'react-icons/io'
import {
    NOTIFICATION_TYPE_ERROR,
    NOTIFICATION_TYPE_INFO,
    NOTIFICATION_TYPE_SUCCESS,
    NOTIFICATION_TYPE_WARNING,
} from '~constants/notification'

const ICONS_PER_KIND: { [key in AlertKind]: ReactNode } = {
    success: <IoMdCheckmarkCircleOutline />,
    info: <IoIosInformationCircleOutline />,
    warning: <IoIosWarning />,
    danger: <IoIosCloseCircleOutline />,
}

export type AlertKind =
    | typeof NOTIFICATION_TYPE_SUCCESS
    | typeof NOTIFICATION_TYPE_INFO
    | typeof NOTIFICATION_TYPE_WARNING
    | typeof NOTIFICATION_TYPE_ERROR

export interface AlertProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode
    kind?: AlertKind
    show?: boolean
    alternativeDisplay?: ReactNode
    onHide?: () => void
    showContainer?: boolean
}

const CloseButton = ({ onHide }: { onHide: () => void }) => {
    return (
        <span className="close" onClick={onHide}>
            <IoMdCloseCircle name="close" />
        </span>
    )
}

/**
 * @visibleName Banner Alert
 */
export const Alert = (props: AlertProps) => {
    const {
        show = true,
        showContainer = true,
        alternativeDisplay,
        children,
        kind = 'info',
        onHide,
        className,
        ...rest
    } = props

    if (!show) {
        return alternativeDisplay
    }

    return (
        <div
            {...rest}
            className={classNames(
                'alert',
                className,
                { [`alert-${kind!}`]: kind },
                { 'container-hidden': !showContainer }
            )}
        >
            {onHide && <CloseButton onHide={onHide} />}
            <div className="alert-icon-box">{ICONS_PER_KIND[kind!]}</div>
            <div className="alert-text">{children}</div>
        </div>
    )
}
