import { ResetStoreActionType } from '~actions'
import { OptionListFields } from '~constants/report'
import { ActionType, createAction } from 'deox'

export const redirectToPathAction = createAction(
    'REDIRECT_TO_PATH',
    resolve => (payload: { pathname: string }) => resolve(payload)
)

export type RedirectToPathActionType = ActionType<typeof redirectToPathAction>

export const fetchTranslatedTextsAction = createAction('FETCH_TRANSLATED_TEXTS')

export const setTranslatedTextsAction = createAction(
    'SET_TRANSLATED_TEXTS',
    resolve =>
        (payload: {
            englishTranslation: { [key: string]: string | string[] }
            bulgarianTranslation: { [key: string]: string | string[] }
            russianTranslation: { [key: string]: string | string[] }
        }) =>
            resolve(payload)
)

export const setLanguageAction = createAction(
    'SET_LANGUAGE',
    resolve => (payload: { lang: OptionListFields }) => resolve(payload)
)

export const resetUserEntitlementsAction = createAction(
    'RESET_USER_ENTITLEMENTS',
    resolve => (payload: { lang: OptionListFields }) => resolve(payload)
)

type SetLanguageActionType = ActionType<typeof setLanguageAction>

type ResetUserEntitlementsActionType = ActionType<typeof resetUserEntitlementsAction>

export type EntitlementsActionType = SetLanguageActionType | ResetUserEntitlementsActionType | ResetStoreActionType
