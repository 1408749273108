import React from 'react'
import { Row, Col, Form, FormGroup, Label, Input, InputGroup } from 'reactstrap'
import { i18n } from '~utils/i18n'
import { Button } from '~components/shared'
import { useDispatch, useSelector } from 'react-redux'
import { setClearContactFormAction, submitContactFormAction } from '~actions/contacts'
import { shouldClearFormStateSelector } from '~reducers/contacts'
import { Link } from 'react-router-dom'
import { PRIVACY_ROUTE } from '~constants/api'
import { selectedLanguageSelector } from '~reducers/navigation'
import classNames from 'classnames'
import { isPortrait } from '~utils/utils'

export const Contacts = () => {
    const dispatch = useDispatch()
    const shouldClearFormState = useSelector(shouldClearFormStateSelector)
    const selectedLanguage = useSelector(selectedLanguageSelector)
    const [fullName, setFullName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [phoneCode, setPhoneCode] = React.useState('')
    const [phoneNumber, setPhoneNumber] = React.useState('')
    const [howCanHelp, setHowCanHelp] = React.useState('')
    const [checkbox, setCheckbox] = React.useState(false)
    const [isFullNameValid, setIsFullNameValid] = React.useState(true)
    const [isEmailValid, setIsEmailValid] = React.useState(true)
    const [isPhoneCodeValid, setIsPhoneCodeValid] = React.useState(true)
    const [isPhoneNumberValid, setIsPhoneNumberValid] = React.useState(true)
    const isFormValid =
        isFullNameValid &&
        fullName &&
        isEmailValid &&
        email &&
        phoneCode &&
        isPhoneCodeValid &&
        phoneNumber &&
        isPhoneNumberValid &&
        howCanHelp &&
        checkbox

    const onSubmit = () => {
        if (isFormValid) {
            dispatch(
                submitContactFormAction({ fullName, email, phoneNumber: `${phoneCode}${phoneNumber}`, howCanHelp })
            )
        }
    }

    React.useEffect(() => {
        if (shouldClearFormState) {
            setFullName('')
            setEmail('')
            setPhoneNumber('')
            setHowCanHelp('')
            setCheckbox(false)
            setIsFullNameValid(true)
            setIsEmailValid(true)
            setIsPhoneCodeValid(true)
            setIsPhoneNumberValid(true)
            dispatch(setClearContactFormAction({ shouldClearFormState: false }))
        }
    }, [shouldClearFormState])
    return (
        <div className="py-4 px-5">
            <Row noGutters className={classNames({ 'flex-column': isPortrait() })}>
                <Col md={6} className="pe-4 text-center" style={{ fontSize: '20px' }}>
                    <p className="text-primary mb-3">{i18n({ key: 'contacts-paragraph-1' })}</p>
                    <p className="text-primary mb-3">{i18n({ key: 'contacts-paragraph-2' })}</p>
                    <p className="text-primary">
                        {i18n({ key: 'contacts-paragraph-3-new-lines' })?.map(item => <div>{item}</div>)}
                    </p>
                    <p className="text-primary mb-3">{i18n({ key: 'contacts-paragraph-4' })}</p>
                    <p className="text-primary mb-3">{i18n({ key: 'contacts-paragraph-5' })}</p>
                </Col>
                <Col md={6}>
                    <Form onSubmit={onSubmit}>
                        <FormGroup className="form-group required">
                            <Label for="fullName">{i18n({ key: 'full-name-label' })}</Label>
                            <Input
                                id="fullName"
                                name="fullName"
                                required
                                type="text"
                                invalid={!isFullNameValid && !!fullName}
                                onBlur={() => setIsFullNameValid(Boolean(fullName.match(/^(\w.+\s).+$/)))}
                                value={fullName}
                                onChange={({ target: { value } }) => {
                                    setFullName(value)
                                    if (!isFullNameValid) {
                                        setIsFullNameValid(Boolean(value.match(/^(\w.+\s).+$/)))
                                    }
                                }}
                            />
                        </FormGroup>
                        <FormGroup className="form-group required">
                            <Label for="email">{i18n({ key: 'email-label' })}</Label>
                            <Input
                                id="email"
                                name="email"
                                required
                                type="email"
                                invalid={!isEmailValid && !!email}
                                onBlur={() => setIsEmailValid(Boolean(email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)))}
                                value={email}
                                onChange={({ target: { value } }) => {
                                    setEmail(value)
                                    if (!isEmailValid) {
                                        setIsEmailValid(Boolean(value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)))
                                    }
                                }}
                            />
                        </FormGroup>
                        <FormGroup className="form-group required">
                            <Label for="phoneNumber">{i18n({ key: 'phone-number-label' })}</Label>
                            <InputGroup>
                                <Input
                                    id="phoneCode"
                                    name="phoneCode"
                                    placeholder={i18n({ key: 'phone-code-placeholder' })}
                                    required
                                    type="text"
                                    value={phoneCode}
                                    invalid={!isPhoneCodeValid && !!phoneCode}
                                    onBlur={() => setIsPhoneCodeValid(Boolean(phoneCode.match(/^\+\d{1,4}$/)))}
                                    onChange={({ target: { value } }) => {
                                        setPhoneCode(value)
                                        if (!isPhoneCodeValid) {
                                            setIsPhoneCodeValid(Boolean(value.match(/^\+\d{1,4}$/)))
                                        }
                                    }}
                                    style={{ maxWidth: '100px' }}
                                />
                                <Input
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    placeholder={i18n({ key: 'phone-number-placeholder' })}
                                    required
                                    type="tel"
                                    value={phoneNumber}
                                    invalid={!isPhoneNumberValid && !!phoneNumber}
                                    onBlur={() => setIsPhoneNumberValid(Boolean(phoneNumber.match(/^[0-9]*$/)))}
                                    onChange={({ target: { value } }) => {
                                        setPhoneNumber(value)
                                        if (!isPhoneNumberValid) {
                                            setIsPhoneNumberValid(Boolean(value.match(/^[0-9]*$/)))
                                        }
                                    }}
                                />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className="form-group required">
                            <Label for="how-can-help">{i18n({ key: 'how-can-help-label' })}</Label>
                            <Input
                                id="how-can-help"
                                name="how-can-help"
                                required
                                type="textarea"
                                value={howCanHelp}
                                onChange={({ target: { value } }) => setHowCanHelp(value)}
                            />
                        </FormGroup>
                        <FormGroup className="form-group required">
                            <Input
                                id="agree-terms"
                                name="agree-terms"
                                required
                                type="checkbox"
                                checked={checkbox}
                                onChange={({ target: { checked } }) => setCheckbox(checked)}
                            />
                            <Label for="agree-terms" className="ps-2 start">
                                {i18n({ key: 'agree-terms-label' })}
                                <Link className="ps-1" to={`${PRIVACY_ROUTE}#${selectedLanguage.value}`}>
                                    {i18n({ key: 'terms-text' })}
                                </Link>
                            </Label>
                        </FormGroup>
                        <Button
                            tooltipId="contacts-button-id"
                            size="none"
                            color="primary"
                            style={{ padding: '10px 50px' }}
                            disabled={!isFormValid}
                            disabledReason={i18n({ key: 'required-fields-disable-reason' })}
                            onClick={onSubmit}
                        >
                            {i18n({ key: 'submit-button' })}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}
