import React, { FC } from 'react'
import { Nav, NavbarText, NavItem } from 'reactstrap'
import { FaInstagram, FaFacebook } from 'react-icons/fa'
import { i18n } from '~utils/i18n'
//import { FaYoutube, FaXTwitter, FaLinkedin } from 'react-icons/fa6'

interface FooterProps {
    isUserAuthenticated: boolean
}

export const Footer: FC<FooterProps> = () => (
    <footer className="footer justify-content-between mt-auto navbar navbar-expand-md bg-light">
        <Nav className="navbar-nav">
            {/* <NavItem className="mr-2">
                <a
                    className="nav-link d-flex align-items-center"
                    href="https://twitter.com/....."
                    target="_blank"
                    title="X Twitter"
                >
                    <FaXTwitter className="mr-1" size="1.6em" />
                </a>
            </NavItem> */}
            <NavItem className="mr-2">
                <a
                    className="nav-link d-flex align-items-center"
                    href={i18n({ key: 'footer-instagram-link' })}
                    target="_blank"
                    title="Instagram"
                >
                    <FaInstagram className="mr-1" size="1.6em" />
                </a>
            </NavItem>
            <NavItem className="mr-2">
                <a
                    className="nav-link d-flex align-items-center"
                    href={i18n({ key: 'footer-facebook-link' })}
                    target="_blank"
                    title="Facebook"
                >
                    <FaFacebook className="mr-1" size="1.6em" />
                </a>
            </NavItem>
            {/* <NavItem className="mr-2">
                <a
                    className="nav-link d-flex align-items-center"
                    href="https://youtube.com/explore/tags/....."
                    target="_blank"
                    title="Youtube"
                >
                    <FaYoutube className="mr-1" size="1.6em" />
                </a>
            </NavItem>
            <NavItem className="mr-2">
                <a
                    className="nav-link d-flex align-items-center"
                    href="http://linkedin.com/....."
                    target="_blank"
                    title="Linkedin"
                >
                    <FaLinkedin className="mr-1" size="1.6em" />
                </a>
            </NavItem> */}
        </Nav>
        <NavbarText style={{ color: '#BF6A02' }}>{i18n({ key: 'footer-middle-text' })}</NavbarText>
        <Nav className="navbar-nav">
            <NavItem className="mr-2">
                <a className="nav-link text-info" href={i18n({ key: 'footer-right-link' })} target="_blank">
                    {i18n({ key: 'footer-right-text' })}
                </a>
            </NavItem>
        </Nav>
    </footer>
)
