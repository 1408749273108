import React, { FC, useState } from 'react'
import {
    Navbar,
    NavbarToggler,
    Collapse,
    Nav,
    NavItem,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { GB, BG, RU } from 'country-flag-icons/react/3x2'
import {
    ABOUT_ROUTE,
    BASE_ROUTE,
    BOOK_ROUTE,
    CONTACTS_ROUTE,
    FAQ_ROUTE,
    PRIVACY_ROUTE,
    TERMS_ROUTE,
} from '~constants/api'
import { i18n } from '~utils/i18n'
import { useDispatch, useSelector } from 'react-redux'
import { selectedLanguageSelector } from '~reducers/navigation'
import { setLanguageAction } from '~actions/navigation'

// @ts-ignore
import logo from '../../images/logo.png'

interface NavigationBarProps {
    headerTitle: React.ReactNode
}

export const NavigationBar: FC<NavigationBarProps> = ({ headerTitle }) => {
    const dispatch = useDispatch()
    const [profileDropdownOpen, setProfileDropdownOpen] = useState(false)
    const [isOpen, setIsOpen] = React.useState(false)
    const selectedLanguage = useSelector(selectedLanguageSelector)

    const toggleProfileDropdown = () => {
        setProfileDropdownOpen(!profileDropdownOpen)
    }
    const getPathWithHash = path => `${path}#${selectedLanguage.value}`

    const toggle = () => setIsOpen(!isOpen)

    return (
        <Navbar color="light" expand="md">
            <Link className="navbar-brand" to={getPathWithHash(BASE_ROUTE)}>
                {headerTitle}
            </Link>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar className="justify-content-between">
                <Nav className="ml-auto d-flex align-items-center" navbar>
                    <NavItem>
                        <Link className="nav-link" to={getPathWithHash(BOOK_ROUTE)}>
                            {i18n({ key: 'book-tab' })}
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link className="nav-link" to={getPathWithHash(ABOUT_ROUTE)}>
                            {i18n({ key: 'about-tab' })}
                        </Link>
                    </NavItem>
                </Nav>
                <img height="40px" src={logo} />
                <Nav className="ml-auto d-flex align-items-center" navbar>
                    <NavItem>
                        <Link className="nav-link" to={getPathWithHash(FAQ_ROUTE)}>
                            {i18n({ key: 'faq-tab' })}
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link className="nav-link" to={getPathWithHash(TERMS_ROUTE)}>
                            {i18n({ key: 'terms-tab' })}
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link className="nav-link" to={getPathWithHash(PRIVACY_ROUTE)}>
                            {i18n({ key: 'privacy-terms-tab' })}
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link className="nav-link" to={getPathWithHash(CONTACTS_ROUTE)}>
                            {i18n({ key: 'contacts-tab' })}
                        </Link>
                    </NavItem>
                    <Dropdown className="ps-3" nav isOpen={profileDropdownOpen} toggle={toggleProfileDropdown}>
                        <DropdownToggle nav caret>
                            {selectedLanguage.value === 'en' && <GB style={{ height: '20px' }} />}
                            {selectedLanguage.value === 'bg' && <BG style={{ height: '20px' }} />}
                            {selectedLanguage.value === 'ru' && <RU style={{ height: '20px' }} />}
                        </DropdownToggle>
                        <DropdownMenu style={{ minWidth: 0 }} end>
                            {selectedLanguage.value !== 'en' && (
                                <DropdownItem
                                    onClick={() => {
                                        dispatch(setLanguageAction({ lang: { label: 'EN', value: 'en' } }))
                                    }}
                                >
                                    <GB style={{ height: '20px' }} />
                                </DropdownItem>
                            )}
                            {selectedLanguage.value !== 'ru' && (
                                <DropdownItem
                                    onClick={() => {
                                        dispatch(setLanguageAction({ lang: { label: 'РУ', value: 'ru' } }))
                                    }}
                                >
                                    <RU style={{ height: '20px' }} />
                                </DropdownItem>
                            )}
                            {selectedLanguage.value !== 'bg' && (
                                <DropdownItem
                                    onClick={() => {
                                        dispatch(setLanguageAction({ lang: { label: 'БГ', value: 'bg' } }))
                                    }}
                                >
                                    <BG style={{ height: '20px' }} />
                                </DropdownItem>
                            )}
                        </DropdownMenu>
                    </Dropdown>
                </Nav>
            </Collapse>
        </Navbar>
    )
}
